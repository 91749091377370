// This file is automatically generated and will be replaced on compile
// See pickit.config.js for details
const config = {
  BASE_URL: 'pickit.com',
  MEDIA_URL: 'files-2.pickit.com',
  keys: {
    DROPBOX_PUBLIC_KEY: '35uorkymkzzohyh',
    HUBSPOT_KEY: '79813a30-3d03-46d9-8d78-e5a501bceb8d',
    BOX_KEY: 'hlvk2pjfpdlbootnx6959w3wmbcd5ie7',
    ADOBE_CLIENT_ID: 'e9c82b8bb7bb4788a90aff3252c38e34',
    ADOBE_CCL_CLIENT_ID: 'b799ca9334fb497b9c6d739a43c95a8b',
    ADOBE_CCL_CLIENT_SECRET: 'p8e-q3sSuLMMHePsDY1zZv0Y37yvaR4v82zS',
    SHUTTERSTOCK_CLIENT_ID: 'QI4e3VtnwA6i2qa2LM8HH3inMPzG1dhi',
    SERVICE_URL: 'https://services.pickit.com',
    PPTX_SERVICE: 'https://pickit-extractor-live.azurewebsites.net',
    GOOGLE_API_KEY: 'AIzaSyDI5PoSYif9M2s-YJ8YPfIzQhS08soWHu0',
    GOOGLE_CLIENT_ID:
      '922775889312-oqn2jpiqqvjgegcfi7tm65etva3bvmgp.apps.googleusercontent.com',
    PICKIT_API: '23bc1999-ae93-4bd1-acc7-641dbf27e7b9',
    STRIPE_PLANS_EU: ['plan_FnN3ePLbB9Kq6X', 'plan_FnN1GiX5JGzm6F'],
    STRIPE_TIERED_PLANS_EU: ['plan_G0WzmqXzX3Y3wT', 'plan_G0WwEElGgz2yYc'],
    STRIPE_PLANS_US: ['plan_G3azb4XckyEzir', 'plan_G3az5CGkVI8fwB'],
    STRIPE_TIERED_PLANS_US: ['plan_G3azT0qlJEb9Nt', 'plan_G3azl8X9o2nz4z'],
    STRIPE_PUBLIC_KEY_EU: 'pk_live_4abBLilfR9peJPFcAmWOWkmE',
    STRIPE_PUBLIC_KEY_US: '	pk_live_iMUvA0CL7ebECGd6UEWEa4OF00BSn9cS3W',
    APP_INSIGHTS_KEY: 'bc1cee9d-43ee-48c2-a6a8-a5e39c3ce0bf',
    APP_STORAGE_NAME: 'liveappusersettings',
    APP_STORAGE_KEY:
      '?sv=2019-02-02&ss=b&srt=sco&sp=rwac&se=3020-02-06T17:36:26Z&st=2020-02-06T09:36:26Z&spr=https&sig=J4cwkP4Y4ZKs32bFxCazrUR8gkuzOToJsaIIk3fAs7c%3D',
  },
  is_docker: false,
};
const pickit = {
  // Add common config values here
  ...config,
  RELEASE: '4.6.0',
  RELEASE_DATE: 1726484105824,
  RELEASE_DATE_ISO: '2024-09-16T10:55:05.824Z',
  RELEASE_ID: 'hhfog',
  insightsKeys: {
    APP_STORAGE_NAME: 'pickitinsightsfeedback',
    APP_STORAGE_KEY:
      '?sv=2018-03-28&ss=b&srt=o&sp=wac&se=3019-08-26T21:41:07Z&st=2019-08-26T13:41:07Z&spr=https&sig=KhDa3QDPsoRqmYcycL%2FRKlRmrtM%2FBSUx5tAg6CGbxJc%3D',
  },
  config: {
    BASE_URL: config.BASE_URL,
    SERVICE_URL: `https://services.${config.BASE_URL}`,
    PPTX_SERVICE: `${config.keys.PPTX_SERVICE}`,
    MEDIA_URL: `https://${config.MEDIA_URL}`,
    FILES_UPLOAD_URL: `https://upload.${config.BASE_URL}`,
    PROXY_URL: config.is_docker
      ? 'pickit-proxy.loc'
      : `proxy.${config.BASE_URL}`,
    PROXY_PROTO: config.is_docker ? 'http' : 'https',
    SHOW_ADMIN_MENU: false,
  },
};

window.pickit = pickit;
// fixme: use that changes only if you are not using docker:
/// window.pickit.config.MEDIA_URL = 'http://127.0.0.1:1995';
export default pickit;
